import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useReactToPrint} from 'react-to-print';

import Page1 from "../components/report/Page1.report.comp";
import Page2 from "../components/report/Page2.report.comp";
import Page3 from "../components/report/Page3.report.comp";
import Page4 from "../components/report/Page4.report.comp";
import Page5 from "../components/report/Page5.report.comp";
import Page6 from "../components/report/Page6.report.comp";
import Page7 from "../components/report/Page7.report.comp";
import Page8 from "../components/report/Page8.report.comp";
import Page9 from "../components/report/Page9.report.comp";
import Page10 from "../components/report/Page10.report.comp";
import Page11 from "../components/report/Page11.report.comp";
import Page12 from "../components/report/Page12.report.comp";
import Page13 from "../components/report/Page13.report.comp";
import Page14 from "../components/report/Page14.report.comp";
import Page15 from "../components/report/Page15.report.comp";
import Page16 from "../components/report/Page16.report.comp";
import Page17 from "../components/report/Page17.report.comp";
import Page18 from "../components/report/Page18.report.comp";
import Page19 from "../components/report/Page19.report.comp";
import Page20 from "../components/report/Page20.report.comp";
import Page21 from "../components/report/Page21.report.comp";
import {useDQCCoverage} from "../hooks/useDQCCoverage.hook";
import {useDepth} from "../hooks/useDepth.hook";
import {useBreadth} from "../hooks/useBreadth.hook";
import Box from "@material-ui/core/Box";
import {APP_STATUS, DQ_DEFINITIONS, SEAL_TYPE} from "../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey, varChunks} from "../../../utils/utils";
import Page16a from "../components/report/Page16a.report.comp";
import {getSampleInstruments} from "../../../services/app.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useLevel6} from "../hooks/useLevel6.hook";
import {useLevel3} from "../hooks/useLevel3.hook";
import {renderToString} from "react-dom/server";
import {RESOURCES_TYPE_MAP} from "../../../utils/META/cat_114";
import {RESOURCES_FORMAT_MAP} from "../../../utils/META/cat_115";
import {PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";
import {REGIONS_MAP} from "../../../utils/META/cat_101";
import {DEVICES_MAP} from "../../../utils/META/cat_116";
import {PROGRAM_VENUE_MAP} from "../../../utils/META/cat_111";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

}))

let enhancement1PageNo=0;
let enhancement2PageNo=0;
let enhancement3PageNo=0;

function varChunksWithFallback(arr, n) {
    if(!arr.length) return [[]];
    return varChunks(arr, n);
}

const Report = forwardRef(function Report(props, ref) {

    const {application_id, programInfo, companyInfo, setLoading} = props;

    const tableContents = useRef({});
    const [LEVEL3, setLEVEL3] = useState({});
    const [LEVEL6, setLEVEL6] = useState({});
    useLevel3(onSetLevel3)
    useLevel6(onSetLevel6)

    //coverage of dqc
    const [DQCCoverage, setDQCCoverage] = useState({});
    const [DQCCoverageMap, setDQCCoverageMap] = useState({});
    useDQCCoverage(application_id, onSetCoverage);
    //depth
    const [depth, setDepth] = useState({});
    const [depthPass, setDepthPass] = useState({});
    const [transformedDepthPass, setTransformedDepthPass] = useState({});
    const [depthMentioned, setDepthMentioned] = useState({});
    const [transformedDepthMentioned, setTransformedDepthMentioned] = useState({});
    const [depthData, setDepthData] = useState(null);
    useDepth(application_id, handleSetDepth);
    //breadth
    const [breadth, setBreadth] = useState({});
    const [breadthFinalAligned, setBreadthFinalAligned] = useState({});
    useBreadth(application_id, onSetBreadth);
    //sample questions
    const [sampleInstruments, setSampleInstruments] = useState([]);
    const [remainingCodes, setRemainingCodes] = useState([]);

    async function fetchSampleInstruments(competencies) {
        const instruments = [];
        setLoading(true);
        try {
            const result = await getSampleInstruments(competencies);
            if(result.data) {
                instruments.push(...result.data);
            }
        } catch (e) {
            console.error(e);
            // already toasted the error
        }
        setLoading(false);
        return instruments;
    }

    function onSetLevel3(object) {
        setLEVEL3(object);
    }

    function onSetLevel6(object) {
        setLEVEL6(object);
    }

    function onSetCoverage(object) {
        setDQCCoverage(object);
        if(object.data) {
            const map = getMapFromObjectListByKey(object.data , "dqc_no");
            setDQCCoverageMap(map);
            fetchSampleInstruments(Object.keys(map)).then((res) => setSampleInstruments(res));
        }
    }

    function handleSetDepth(object) {
        setDepthData(object);
    }

    useEffect(() => {
        if (Object.keys(LEVEL3).length > 0 && depthData) {
            onSetDepth(depthData);
        }
    }, [LEVEL3, depthData]);

    const transformDepth = (data) => {
        const result = [];
        const seenDQCNo = new Set();

        data.forEach(item => {
            result.push(item);
            if (!seenDQCNo.has(item.dqc_no)) {
                seenDQCNo.add(item.dqc_no);
                result.push({ dqc_no: item.dqc_no, type: 'dqc' });
            }
        });

        return { data: result };
    };

    useEffect(() => {
        if(depthPass.data) {
            const transformedData = transformDepth(depthPass.data);
            setTransformedDepthPass(transformedData);
        }
    }, [depth, depthPass])

    useEffect(() => {
        if(depthMentioned.data) {
            const transformedData = transformDepth(depthMentioned.data);
            setTransformedDepthMentioned(transformedData);
        }
    }, [depth, depthMentioned])

    function onSetDepth(object) {
        setDepth(object);
        //calculate remaining codes
        function getDQCode(dqc_no) {
            const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
            return isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        }
        const aligned_codes_to_filter = object.data ? object.data.map((item) => getDQCode(item.dqc_no)) : []
        const additional_codes = Array.from({ length: 16 }, (_, i) => `DQ${i + 9}`); // DQ9 to DQ24
        const codes_to_filter = [...new Set([...aligned_codes_to_filter, ...additional_codes])];
        const codes = Object.keys(LEVEL3).filter((code) => !codes_to_filter.includes(code)).map((code) => `${code}: ${LEVEL3[code].title}`)
        setRemainingCodes(codes);
        setDepthPass({data: object.data.filter(item => item.pass === "Pass")});
        setDepthMentioned({data: object.data.filter(item => item.pass === "Mentioned")});
    }

    function onSetBreadth(object) {
        setBreadth(object);
        setBreadthFinalAligned({data: object.data.filter(item => item.final_aligned === 1)})
    }

    let pageNumber = 1;
    function getPageNumber(content = undefined) {
        if(content) {
            if(content==="Enhancement-I")       { enhancement1PageNo=pageNumber; }
            else if (content==="Enhancement-II"){ enhancement2PageNo=pageNumber; }
            else if(content==="Enhancement-III"){enhancement3PageNo=pageNumber;}
            else {
                tableContents.current = {...tableContents.current, [content]: pageNumber}
            }
        }
        return pageNumber++;
    }

    function getAnalysisData(analysis, filter_function = () => true) {
        return analysis.data ? analysis.data.filter(filter_function) : [];
    }

    function getChunkedAnalysis(analysis, total_chunks, chunk, index) {
        if(analysis.data) {
            const is_last = index === (total_chunks - 1);
            const grand_total = is_last ? analysis.grand_total : undefined;
            return {...analysis, data: chunk, grand_total};
        }
        return {};
    }

    const enhancementPageNumbers = {
        enhancement1PageNo,
        enhancement2PageNo,
        enhancement3PageNo,
    }

    const defaultProps = {application_id};

    const program_overview_rows = [
        { title: 'Organization', value: companyInfo.NameOrg },
        { title: 'Contact person', value: companyInfo.NameContact },
        { title: 'Program title', value: programInfo.ProgramName },
        { title: 'Program objectives', value: programInfo.ProgramAims },
        { title: 'Type of resources', value: programInfo.ResourcesType ? programInfo.ResourcesType.split(', ').map(val => RESOURCES_TYPE_MAP[val]?.label || val).join(', ') : programInfo.ResourcesType },
        ...(programInfo.ResourcesType && programInfo.ResourcesType.includes('Others') ? [{ title: 'Other type of resources', value: programInfo.ResourcesTypeOther }] : []),
        { title: 'Format of resources', value: programInfo.ResourcesFormat ? programInfo.ResourcesFormat.split(', ').map(val => RESOURCES_FORMAT_MAP[val]?.label || val).join(', ') : programInfo.ResourcesFormat },
        ...(programInfo.ResourcesFormat && programInfo.ResourcesFormat.includes('Others') ? [{ title: 'Other format of resources', value: programInfo.ResourcesFormatOther }] : []),
        { title: 'Target audience', value: programInfo.ProgramTarget ? programInfo.ProgramTarget.split(', ').map(val => PROGRAM_TARGETS_MAP[val]?.label || val).join(', ') : programInfo.ProgramTarget },
        ...(programInfo.ProgramTarget && programInfo.ProgramTarget.includes('Others') ? [{ title: 'Other type of target audience', value: programInfo.ProgramTargetOther }] : []),
        ...(programInfo.ProgramTarget && (programInfo.ProgramTarget.includes('Teens') || programInfo.ProgramTarget.includes('Youth') || programInfo.ProgramTarget.includes('EarlyYears') || programInfo.ProgramTarget.includes('Children')) ? [
            { title: 'Program target minimum age', value: programInfo.ProgramTargetMinAge },
            { title: 'Program target maximum age', value: programInfo.ProgramTargetMaxAge }
        ] : []),
        { title: 'Target countries', value: programInfo.TargetCountry ? programInfo.TargetCountry.split(', ').map(val => REGIONS_MAP[val]?.name || val).join(', ') : programInfo.TargetCountry },
        { title: 'Device(s) needed by target audience', value: programInfo.Devices ? programInfo.Devices.split(', ').map(val => DEVICES_MAP[val]?.label || val).join(', ') : programInfo.Devices },
        ...(programInfo.Devices && programInfo.Devices.includes('Others') ? [{ title: 'Other type of device(s)', value: programInfo.DeviceTypeOther }] : []),
        { title: 'Venue(s) where program is conducted', value: programInfo.ProgramVenue ? programInfo.ProgramVenue.split(', ').map(val => PROGRAM_VENUE_MAP[val]?.label || val).join(', ') : programInfo.ProgramVenue },
        ...(programInfo.ProgramVenue && programInfo.ProgramVenue.includes('Others') ? [{ title: 'Other type of device(s)', value: programInfo.ProgramVenueOther }] : []),
        { title: 'Link to program', value: programInfo.Links }
    ];

    // TODO: Need to refactor code
    // For Page 4
    function measureProgramOverviewRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';

        const tbody = document.createElement('tbody');
        const tr = document.createElement('tr');

        const td1 = document.createElement('td');
        td1.style.padding = '10px 20px';
        // td1.style.border = '1px solid black';
        td1.style.width = '316.5px';

        const td2 = document.createElement('td');
        td2.style.padding = '1px 20px';
        // td2.style.border = '1px solid black';
        td2.style.width = '316.5px';

        // const titleElement = document.createElement('div');
        td1.style.fontWeight = 'bold';
        td1.style.fontSize = '20px';
        td1.style.textAlign = 'left';
        td1.style.lineHeight = '27px';
        td1.innerText = row.title;

        // const valueElement = document.createElement('div');
        td2.style.fontWeight = 'lighter';
        td2.style.fontSize = '18px';
        td2.style.fontFamily = 'Source Sans Pro';
        td2.style.textAlign = 'left';
        td2.style.wordWrap = 'break-word';
        td2.style.lineHeight = '27px';
        td2.innerText = row.value;

        // td1.appendChild(titleElement);
        // td2.appendChild(valueElement);
        tr.appendChild(td1);
        tr.appendChild(td2);
        tbody.appendChild(tr);
        table.appendChild(tbody);

        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td2.offsetWidth;
        console.log("row height of content: ", row.value, "height", height)
        document.body.removeChild(table);
        return {height, width};
    }

    // For Appendix A
    function measureAppendixARowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';

        const tbody = document.createElement('tbody');
        const tr = document.createElement('tr');

        const td1 = document.createElement('td');
        td1.style.padding = '5px 10px';
        // td1.style.border = '1px solid black';
        td1.style.width = '189.89px';

        const td2 = document.createElement('td');
        td2.style.padding = '5px 10px';
        // td2.style.border = '1px solid black';
        td2.style.width = '443.11px';

        // const firstColumn = document.createElement('div');
        td1.style.fontWeight = 'normal';
        td1.style.fontSize = '15px';
        td1.style.fontFamily = 'Source Sans Pro';
        td1.style.textAlign = 'left';
        td1.style.lineHeight = '21px';
        td1.innerText = row.code + " " + row.title;

        // const secondColumn = document.createElement('div');
        td2.style.fontWeight = 'normal';
        td2.style.fontSize = '15px';
        td2.style.fontFamily = 'Source Sans Pro';
        td2.style.textAlign = 'left';
        td2.style.lineHeight = '21px';
        td2.innerText = row.definition;

        // td1.appendChild(firstColumn);
        // td2.appendChild(secondColumn);
        tr.appendChild(td1);
        tr.appendChild(td2);
        tbody.appendChild(tr);
        table.appendChild(tbody);

        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td2.offsetWidth;
        console.log("row height of content: ", row.definition, "height", height)
        document.body.removeChild(table);
        return {height, width};
    }

    // For Appendix B
    function measureAppendixBRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';

        const tbody = document.createElement('tbody');
        const tr = document.createElement('tr');

        const td1 = document.createElement('td');
        td1.style.padding = '5px 10px';
        // td1.style.border = '1px solid black';
        td1.style.width = '189.89px';

        const td2 = document.createElement('td');
        td2.style.padding = '5px 10px';
        // td2.style.border = '1px solid black';
        td2.style.width = '443.11px';

        // const firstColumn = document.createElement('div');
        td1.style.fontWeight = 'normal';
        td1.style.fontSize = '15px';
        td1.style.fontFamily = 'Source Sans Pro';
        td1.style.textAlign = 'left';
        td1.style.lineHeight = '21px';
        td1.innerText = row.mb_no + " " + row.title;

        // const secondColumn = document.createElement('div');
        td2.style.fontWeight = 'normal';
        td2.style.fontSize = '15px';
        td2.style.fontFamily = 'Source Sans Pro';
        td2.style.textAlign = 'left';
        td2.style.lineHeight = '21px';
        td2.innerText = row.definition;

        // td1.appendChild(firstColumn);
        // td2.appendChild(secondColumn);
        tr.appendChild(td1);
        tr.appendChild(td2);
        tbody.appendChild(tr);
        table.appendChild(tbody);

        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td2.offsetWidth;
        console.log("row height of content: ", row.definition, "height", height)
        document.body.removeChild(table);
        return {height, width};
    }

    // For Page 9
    function measureDQCompetencyRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';
        // table.style.width = '100%';

        const tbody = document.createElement('tbody');
        const tr = document.createElement('tr');

        const td1 = document.createElement('td');
        const td2 = document.createElement('td');
        const td3 = document.createElement('td');

        td1.style.border = '1px solid grey';
        td1.style.padding = '5px 10px';
        td1.style.fontSize = '18px';
        td1.style.textAlign = 'left';
        td1.style.width = '316.5px';
        td1.style.fontFamily = 'Source Sans Pro';

        td2.style.border = '1px solid grey';
        td2.style.padding = '5px 10px';
        td2.style.fontSize = '18px';
        td2.style.textAlign = 'center';
        td2.style.width = '158.25px';
        td2.style.fontFamily = 'Source Sans Pro';

        td3.style.border = '1px solid grey';
        td3.style.padding = '5px 10px';
        td3.style.fontSize = '18px';
        td3.style.textAlign = 'center';
        td3.style.width = '158.25px';
        td3.style.fontFamily = 'Source Sans Pro';
        td3.style.fontWeight = 'bold';

        td1.innerText = row.dqc_name;
        td2.innerText = row.count;
        td3.innerText = row.percent;

        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.appendChild(td3);
        tbody.appendChild(tr);
        table.appendChild(tbody);

        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td1.offsetWidth;
        console.log("row height of content: ", row.dqc_name, "height", height);
        document.body.removeChild(table);
        return {height, width};
    }

    // For Page 10
    function measureAnalysisIIRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';
        // table.style.width = '100vw';

        const tbody = document.createElement('tbody');

        const tr = document.createElement('tr');
        const td1 = document.createElement('td');
        const td2 = document.createElement('td');

        td1.style.border = '1px solid black';
        td1.style.padding = '5px 10px';
        td1.style.fontSize = '18px';
        td1.style.fontFamily = 'Source Sans Pro';
        td1.style.width = '443.09px';
        td1.innerText = row.title;

        td2.style.border = '1px solid black';
        td2.style.padding = '5px 10px';
        td2.style.fontSize = '18px';
        td2.style.fontFamily = 'Source Sans Pro';
        td2.style.fontWeight = 'bold';
        td2.style.textAlign = 'center';
        td2.style.width = '189.91px';

        if (row.isBadge) {
            td1.style.fontWeight = 'bold';
            td2.innerText = '';
        } else {
            td1.style.fontWeight = 'normal';
            td2.innerText = row.count;
        }

        tr.appendChild(td1);
        tr.appendChild(td2);
        tbody.appendChild(tr);

        table.appendChild(tbody);
        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td1.offsetWidth;
        console.log("page 10 row height of content: ", row.title, "height: ", height)
        document.body.removeChild(table);
        return {height, width};
    }

    // For Page 11
    function measureAnalysisIIIRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';

        const tbody = document.createElement('tbody');
        const tr = document.createElement('tr');

        const td1 = document.createElement('td');
        const td2 = document.createElement('td');
        const td3 = document.createElement('td');
        const td4 = document.createElement('td');

        td1.style.border = '1px solid black';
        td1.style.padding = '5px 10px';
        td1.style.fontSize = '18px';
        td1.style.textAlign = 'left';
        td1.style.width = '316.5px';
        td1.style.fontFamily = 'Source Sans Pro';

        td2.style.border = '1px solid black';
        td2.style.padding = '5px 10px';
        td2.style.fontSize = '18px';
        td2.style.textAlign = 'center';
        td2.style.width = '94.94px';
        td2.style.fontFamily = 'Source Sans Pro';

        td3.style.border = '1px solid black';
        td3.style.padding = '5px 10px';
        td3.style.fontSize = '18px';
        td3.style.textAlign = 'center';
        td3.style.width = '94.94px';
        td3.style.fontFamily = 'Source Sans Pro';

        td4.style.border = '1px solid black';
        td4.style.padding = '5px 10px';
        td4.style.fontSize = '18px';
        td4.style.textAlign = 'center';
        td4.style.width = '126.61px';
        td4.style.fontFamily = 'Source Sans Pro';

        td1.innerText = row.title;
        td2.innerText = row.mb;
        td3.innerText = row.count;
        td4.innerText = row.aligned ? "Yes" : "No";

        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.appendChild(td3);
        tr.appendChild(td4);
        tbody.appendChild(tr);
        table.appendChild(tbody);

        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td1.offsetWidth;
        console.log("row height of content: ", row.title, "height: ", height);
        document.body.removeChild(table);
        return {height, width};
    }

    // For Page 13
    function measureDepthMentionedRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';
        // table.style.width = '100vw';

        const tbody = document.createElement('tbody');

        const tr = document.createElement('tr');
        const td1 = document.createElement('td');
        const td2 = document.createElement('td');

        td1.style.border = '1px solid black';
        td1.style.padding = '5px 10px';
        td1.style.fontSize = '18px';
        td1.style.fontFamily = 'Source Sans Pro';
        td1.style.width = '443.09px';
        td1.innerText = row.title;

        td2.style.border = '1px solid black';
        td2.style.padding = '5px 10px';
        td2.style.fontSize = '18px';
        td2.style.fontFamily = 'Source Sans Pro';
        td2.style.fontWeight = 'bold';
        td2.style.textAlign = 'center';
        td2.style.width = '189.91px';

        if (row.isBadge) {
            td1.style.fontWeight = 'bold';
            td2.innerText = '';
        } else {
            td1.style.fontWeight = 'normal';
            td2.innerText = row.count;
        }

        tr.appendChild(td1);
        tr.appendChild(td2);
        tbody.appendChild(tr);

        table.appendChild(tbody);
        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td1.offsetWidth;
        console.log("page 13 row height of content: ", row.title, "height: ", height)
        document.body.removeChild(table);
        return {height, width};
    }

    // For Page 16a
    function measureBreadthFinalAlignedRowHeight(row) {
        const table = document.createElement('table');
        table.style.position = 'absolute';
        table.style.visibility = 'hidden';
        table.style.borderCollapse = 'collapse';

        const tbody = document.createElement('tbody');
        const tr = document.createElement('tr');

        const td1 = document.createElement('td');
        const td2 = document.createElement('td');

        td1.style.border = '1px solid black';
        td1.style.padding = '5px 10px';
        td1.style.fontSize = '18px';
        td1.style.textAlign = 'left';
        td1.style.width = '189.89px';
        td1.style.fontFamily = 'Source Sans Pro';
        td1.style.lineHeight = '22.5px';

        td2.style.border = '1px solid black';
        td2.style.padding = '5px 10px';
        td2.style.fontSize = '16px';
        td2.style.textAlign = 'center';
        td2.style.width = '443.11px';
        td2.style.fontFamily = 'Source Sans Pro';
        td2.style.lineHeight = '22.5px';

        td1.innerText = row.title;
        td2.innerText = row.definition;

        tr.appendChild(td1);
        tr.appendChild(td2);
        tbody.appendChild(tr);
        table.appendChild(tbody);

        document.body.appendChild(table);
        const height = table.offsetHeight;
        const width = td2.offsetWidth;
        console.log("row height of content: ", row.title, "height: ", height);
        document.body.removeChild(table);
        return {height, width};
    }


    const appendix_a_rows = [];
    const appendix_b_rows = [];
    const dqc_competency_rows = [];
    const transformed_depth_pass_rows = [];
    let analysis_3_rows = [];
    const breadth_final_aligned_rows = [];
    const transformed_depth_mentioned_rows = [];

    // For Appendix A and Page 9
    if(DQCCoverage.data && LEVEL3) {
        for(const item of DQCCoverage.data) {
            const {dqc_no} = item;
            const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
            const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
            const title = LEVEL3[code] ? LEVEL3[code].title : ""
            const definition = LEVEL3[code] ? LEVEL3[code].definition: "";
            appendix_a_rows.push({code, title, definition});
            dqc_competency_rows.push({
                dqc_name: getDQCName(item.dqc_no),
                count: item.count,
                percent: item.percent.toFixed(2) + '%'
            });
        }
    }

    // For Appendix B
    if(depthPass.data && LEVEL6) {
        for(const item of depthPass.data) {
            const {mb_no} = item;
            const level6_entry = LEVEL6.find(entry => entry.gsm_no === mb_no);
            const title = level6_entry ? level6_entry.full_name : "";
            const definition = level6_entry ? level6_entry.definition : "";
            appendix_b_rows.push({mb_no, title, definition});
        }
    }

    // For Page 9
    function getDQC(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const title = LEVEL3[code] ? LEVEL3[code].title : ""
        const color = LEVEL3[code] ? LEVEL3[code].color : "#444"
        return {code, title, color};
    }
    function getDQCName(dqc_no) {
        const {title, code} = getDQC(dqc_no);
        return code + (title ? `: ${title}` : '');
    }

    // For Page 10
    if(transformedDepthPass.data && LEVEL3 && DQCCoverageMap) {
        const badges = {};
        for(const item of transformedDepthPass.data) {
            if(!item.type) {
                const {dqc_no, mb_no, mb_name, level9_count, is_first_mb} = item;
                const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
                const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
                const title = LEVEL3[code] ? LEVEL3[code].title : ""
                if (!badges[dqc_no]) badges[dqc_no] = {micro_badges: []}
                if (!badges[dqc_no].badge) badges[dqc_no].badge = {code, title, count: DQCCoverageMap[dqc_no].count}
                badges[dqc_no].micro_badges.push({title: `${mb_no}: ${mb_name}`, count: level9_count})
                badges[dqc_no].micro_badges.sort(asciiSortCompareFunWithKey('title'));
            }
        }
        Object.entries(badges).forEach(([_, badge]) => {
            transformed_depth_pass_rows.push({
                title: badge.badge.code + ' ' + badge.badge.title,
                count: null,
                isBadge: true
            });

            badge.micro_badges.forEach(micro_badge => {
                transformed_depth_pass_rows.push({
                    title: micro_badge.title,
                    count: micro_badge.count,
                    isBadge: false
                });
            });
        });
    }


    // For Page 11
    function getDQ(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const title = LEVEL3[code] ? LEVEL3[code].title : "";
        return code + ' ' + title;
    }
    if(breadth.data && LEVEL3 && DQCCoverageMap) {
        analysis_3_rows = breadth.data.map(b => ({
            title: getDQ(b.dqc_no),
            mb: b.count,
            count: DQCCoverageMap[b.dqc_no] ? DQCCoverageMap[b.dqc_no].count : 0,
            aligned: b.final_aligned
        }));
    }

    // For Page 13
    if(transformedDepthMentioned.data && LEVEL3 && DQCCoverageMap) {
        const badges = {};
        for(const item of transformedDepthMentioned.data) {
            if(!item.type) {
                const {dqc_no, mb_no, mb_name, level9_count, is_first_mb} = item;
                const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
                const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
                const title = LEVEL3[code] ? LEVEL3[code].title : ""
                if (!badges[dqc_no]) badges[dqc_no] = {micro_badges: []}
                if (!badges[dqc_no].badge) badges[dqc_no].badge = {code, title, count: DQCCoverageMap[dqc_no].count}
                badges[dqc_no].micro_badges.push({title: `${mb_no}: ${mb_name}`, count: level9_count})
                badges[dqc_no].micro_badges.sort(asciiSortCompareFunWithKey('title'));
            }
        }
        Object.entries(badges).forEach(([_, badge]) => {
            transformed_depth_mentioned_rows.push({
                title: badge.badge.code + ' ' + badge.badge.title,
                count: null,
                isBadge: true
            });

            badge.micro_badges.forEach(micro_badge => {
                transformed_depth_mentioned_rows.push({
                    title: micro_badge.title,
                    count: micro_badge.count,
                    isBadge: false
                });
            });
        });
    }

    // For Page 16a
    if(breadthFinalAligned.data && LEVEL3) {
        for(const item of breadthFinalAligned.data) {
            const {dqc_no} = item;
            const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
            const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
            const title = LEVEL3[code] ? LEVEL3[code].title : ""
            const definition = LEVEL3[code] ? LEVEL3[code].definition : "";
            breadth_final_aligned_rows.push({
                title: code + ' ' + title,
                definition: definition
            });
        }
    }

    // For Splitting rows if overflow
    function splitRow(row, remainingHeight, measureRowHeight, contentColumn, nonRepeatingColumn, splitLimit) {
        let part1 = Object.assign({}, row);
        let part2 = Object.assign({}, row);

        let content = row[contentColumn];
        if (typeof content !== 'string') {
            if (content == null) {
                return [row, {}];
            }
            content = String(content);
        }

        let originalHeight = measureRowHeight(row).height;
        let splitRatio = remainingHeight / originalHeight;

        let approxIndex = Math.ceil(content.length * splitRatio);

        let splitIndex = approxIndex;
        while (splitIndex > 0 && content[splitIndex] !== ' ') {
            splitIndex--;
        }

        if (splitIndex === 0) {
            splitIndex = approxIndex;
            while (splitIndex < content.length && content[splitIndex] !== ' ') {
                splitIndex++;
            }
            if (splitIndex === content.length) {
                splitIndex = approxIndex;
            }
        }

        part1[contentColumn] = content.slice(0, splitIndex).trim();
        part2[contentColumn] = content.slice(splitIndex).trim();
        nonRepeatingColumn.forEach(col => part2[col] = '');

        let part1Height = measureRowHeight(part1).height;

        let part1Content = part1[contentColumn];
        let part2Words = part2[contentColumn].split(' ');

        for (let i = 0; i < part2Words.length; i++) {
            let testContent = `${part1Content} ${part2Words[i]}`;
            part1[contentColumn] = testContent.trim();

            let newHeight = measureRowHeight(part1).height;
            if (newHeight > part1Height && part1Content) {
                break;
            }

            part1Content = testContent;
            part1Height = newHeight;
        }

        part1[contentColumn] = part1Content.trim();
        part2[contentColumn] = content.slice(part1Content.length).trim();

        nonRepeatingColumn.forEach(col => part2[col] = '');

        if (part2[contentColumn].length < splitLimit) {
            part1[contentColumn] = content.trim();
            return [part1, {}];
        }

        if (!part2[contentColumn]) {
            return [part1, {}];
        }

        return [part1, part2];
    }

    // Chunk by rows height
    function chunkRowsByHeight(rows, HEIGHT, measureRowHeight, contentColumn, nonRepeatingColumn, doSplit= false, splitLimit = 0) {
        let chunks = [];
        let currentChunk = [];
        let currentHeight = 0;
        let maxHeight = HEIGHT[0];

        rows.forEach(row => {
            const { height: rowHeight, width: rowWidth } = measureRowHeight(row);
            if (currentHeight + rowHeight > maxHeight) {
                if (doSplit) {
                    let remainingHeight = maxHeight - currentHeight;
                    if (remainingHeight > 0) {
                        let [part1, part2] = splitRow(row, remainingHeight, measureRowHeight, contentColumn, nonRepeatingColumn, splitLimit);
                        currentChunk.push(part1);
                        chunks.push(currentChunk);
                        if (part2 && Object.keys(part2).length > 0) {
                            currentChunk = [part2];
                            currentHeight = measureRowHeight(part2).height;
                        } else {
                            currentChunk = [];
                            currentHeight = 0;
                        }
                    } else {
                        chunks.push(currentChunk);
                        currentChunk = [row];
                        currentHeight = rowHeight;
                    }
                } else {
                    chunks.push(currentChunk);
                    currentChunk = [row];
                    currentHeight = rowHeight;
                }
                maxHeight = HEIGHT[chunks.length === 0 ? 0 : 1];
            } else {
                currentChunk.push(row);
                currentHeight += rowHeight;
            }
        });

        if (currentChunk.length > 0) {
            chunks.push(currentChunk);
        }

        return chunks;
    }
    const MAX_PAGE_HEIGHT = 1000;
    const program_overview_chunks = chunkRowsByHeight(program_overview_rows, [950, MAX_PAGE_HEIGHT], measureProgramOverviewRowHeight, 'value', ['title'], true, 30);
    const appendix_a_chunks = chunkRowsByHeight(appendix_a_rows, [800, MAX_PAGE_HEIGHT], measureAppendixARowHeight, 'definition', ['code', 'title'], true, 55);
    const appendix_b_chunks = chunkRowsByHeight(appendix_b_rows, [800, MAX_PAGE_HEIGHT], measureAppendixBRowHeight, 'definition', ['mb_no', 'title'], true, 55);
    const dqc_competency_chunks = chunkRowsByHeight(dqc_competency_rows, [900, MAX_PAGE_HEIGHT], measureDQCompetencyRowHeight, 'dqc_name', ['count', 'percent']);
    const transformed_depth_pass_chunks = chunkRowsByHeight(transformed_depth_pass_rows, [480, MAX_PAGE_HEIGHT+50], measureAnalysisIIRowHeight, 'title', ['count']);
    const analysis_3_chunks = chunkRowsByHeight(analysis_3_rows, [280, MAX_PAGE_HEIGHT], measureAnalysisIIIRowHeight, 'title', ['mb', 'count', 'aligned']);
    const increase_breadth_chunks = chunkRowsByHeight(analysis_3_rows, [700, MAX_PAGE_HEIGHT], measureAnalysisIIIRowHeight, 'title', ['mb', 'count', 'aligned']);
    const breadth_final_aligned_chunks = chunkRowsByHeight(breadth_final_aligned_rows, [780, MAX_PAGE_HEIGHT], measureBreadthFinalAlignedRowHeight, 'definition', ['title'], true, 55);
    const transformed_depth_mentioned_chunks = chunkRowsByHeight(transformed_depth_mentioned_rows, [550, MAX_PAGE_HEIGHT+50], measureDepthMentionedRowHeight, 'title', ['count']);

    return <div ref={ref}>
        <Page1 pageNumber={getPageNumber()} companyInfo={companyInfo} {...defaultProps}/>
        <Page2 pageNumber={getPageNumber()} contents={tableContents.current} {...defaultProps}/>
        <Page3 pageNumber={getPageNumber(['1. Introduction', '1.1 About the DQ Standards Conformity Test Report'])} {...defaultProps}/>
        {
            program_overview_chunks.map((chunk, index) =>
                <Page4
                    chunk={chunk}
                    index={index}
                    pageNumber={getPageNumber(['1.2 Program Overview'])}
                    {...defaultProps}
                />
            )
        }
        <Page6 pageNumber={getPageNumber(['1.3 About the DQ Global Standards Framework 2.0'])} {...defaultProps}/>
        <Page7 pageNumber={getPageNumber(['1.4 About the DQ Global Standards Micro-credentials (GSM) for Digital Competencies'])} {...defaultProps}/>
        {DQCCoverage && DQCCoverage.data &&
            [...varChunksWithFallback(getAnalysisData(DQCCoverage).sort((a, b) => b.percent - a.percent), [15, 30])].map((chunk, index, chunks) =>

                <Page8
                    overallCoverage={getChunkedAnalysis(DQCCoverage, chunks.length, chunk, index)}
                    pageNumber={getPageNumber(['2. DQ SCT Analyses', '2.1 Analysis I: Coverage of DQ Competencies'])}
                    coverage={DQCCoverage}
                    onlyTable={!!index} {...defaultProps}
                    isLastPage={index === (chunks.length - 1)}
                    DQ_DEFINITIONS={LEVEL3}
                    xMax={Math.ceil(Math.max(...DQCCoverage.data.map(item => item.percent)) / 10) * 10}
                />
            )
        }
        {
            // [...varChunksWithFallback(getAnalysisData(DQCCoverage), [16])].map((chunk, index, chunks) =>
            dqc_competency_chunks.map((chunk, index) =>
                <Page9
                    overallCoverage={DQCCoverage}
                    pageNumber={getPageNumber()}
                    coverage={chunk}
                    onlyTable={!!index} {...defaultProps}
                    isLastPage={index === (dqc_competency_chunks.length - 1)}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {
            // [...varChunksWithFallback(getAnalysisData(transformedDepthPass), [11, 25])].map((chunk, index, chunks) =>
            transformed_depth_pass_chunks.map((chunk, index) =>
                <Page10
                    depth={chunk}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber(['2.2 Analysis II: The content is aligned with the following DQ GSMs based on the depth of the learning messages'])}
                    onlyTable={!!index} {...defaultProps}
                    isLastPage={index === (transformed_depth_pass_chunks.length - 1)}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {
            // [...varChunksWithFallback(getAnalysisData(breadth), [5, 20])].map((chunk, index, chunks) =>
            analysis_3_chunks.map((chunk, index) =>
                <Page11
                    breadth={chunk}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber(['2.3 Analysis III: Breadth of learning messages meeting DQ Competency Standards'])}
                    onlyTable={!!index} {...defaultProps}
                    isLastPage={index === (analysis_3_chunks.length - 1)}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {
            [...varChunksWithFallback(getAnalysisData(breadth, (item) => item.final_aligned), [12, 12, 10])].map((chunk, index, chunks) =>
                <Page12
                    programInfo={programInfo}
                    breadth={getChunkedAnalysis(breadth, chunks.length, chunk, index)}
                    pageNumber={getPageNumber(!index ? ['2.4 Aligned DQ Competencies'] : undefined)}
                    onlyTable={!!index} {...defaultProps}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {
            // [...varChunksWithFallback(getAnalysisData(transformedDepthMentioned), [12, 25])].map((chunk, index, chunks) =>
            transformed_depth_mentioned_chunks.map((chunk, index) =>
                <Page13
                    depth={chunk}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber(['3. DQ SEAL Enhancement', '3.1 Enhancement of Learning Content', '3.1.1 Increase the Depth of Learning Messages'])}
                    onlyTable={!!index} {...defaultProps}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {
            // [...varChunksWithFallback(getAnalysisData(breadth), [14, 18])].map((chunk, index, chunks) =>
            increase_breadth_chunks.map((chunk, index) =>
                <Page14
                    breadth={chunk}
                    coverageMap={DQCCoverageMap}
                    pageNumber={getPageNumber(['3.1.2 Increase the Breadth of Learning Messages'])}
                    onlyTable={!!index} {...defaultProps}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {
            [...varChunksWithFallback(remainingCodes, [9, 16])].map((chunk, index) =>
                <Page15
                    codes={chunk}
                    pageNumber={getPageNumber(['3.1.3 Increase the Holistic Learning Experience'])}
                    onlyTable={!!index} {...defaultProps}
                />
            )
        }
        <Page16 pageNumber={getPageNumber(['3.2 Impact Tracking with a Curated DQ Assessment'])} {...defaultProps}/>
        {
            // [...varChunksWithFallback(getAnalysisData(breadthFinalAligned), [8, 10])].map((chunk, index, chunks) =>
            breadth_final_aligned_chunks.map((chunk, index) =>
                <Page16a
                    breadth={chunk}
                    pageNumber={getPageNumber(['3.2.1 Measure Your Aligned DQ Competency'])}
                    onlyTable={!!index} {...defaultProps}
                    isLastPage={index === (breadth_final_aligned_chunks.length - 1)}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        <Page17 pageNumber={getPageNumber(['4. About Our Organization', '4.1 DQ Institute', '4.2 DQ Lab'])} {...defaultProps}/>
        <Page18 pageNumber={getPageNumber(['5. Using the DQ Brand', '5.1 The “Powered by DQ” Logo'])} {...defaultProps}/>
        {
            // [...varChunksWithFallback(getAnalysisData(DQCCoverage).sort(asciiSortCompareFunWithKey('dqc_no')), [11, 12])].map((chunk, index) =>
            appendix_a_chunks.map((chunk, index) =>
                <Page19
                    coverage={chunk}
                    instruments={sampleInstruments}
                    pageNumber={getPageNumber(['6. Appendices', '6.1 Appendix A: Description of DQ Competencies mentioned in Analysis I'])}
                    onlyTable={!!index} {...defaultProps}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        {depthPass &&
            // [...varChunksWithFallback(getAnalysisData(depthPass), [8, 8])].map((chunk, index, chunks) =>
            appendix_b_chunks.map((chunk, index) =>
                <Page20
                    LEVEL6={LEVEL6}
                    coverage={chunk}
                    instruments={sampleInstruments}
                    pageNumber={getPageNumber(['6.2 Appendix B: Description of DQ GSMs mentioned in Analysis II'])}
                    onlyTable={!!index} {...defaultProps}
                    DQ_DEFINITIONS={LEVEL3}
                />
            )
        }
        <Page21 pageNumber={getPageNumber()} {...defaultProps}/>
    </div>
})

function ReportModule(props) {

    const {app, application_id, updateStatus, status} = props;

    const classes = useStyles();
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleGetCertify = () => {
        updateStatus(APP_STATUS.READY_TO_CERTIFY, true);
    }

    return (
        <>
            <Box my={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                <Button variant="contained" onClick={handlePrint}  size="large" style={{padding:'10px 40px',backgroundColor:'#052F44', color:'white',}}>Print</Button>
                {
                    app.dqseal_type === SEAL_TYPE.CERTIFY &&
                    status < APP_STATUS.READY_TO_CERTIFY &&
                    <Button variant="contained" onClick={handleGetCertify} size="large" style={{backgroundColor:'#EE6628', padding:'10px 30px', color:'white'}}>Add Impact Tracking</Button>
                }
            </Box>
            <Report ref={componentRef} setLoading={setLoading} {...props}/>
            <Box my={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                <Button variant="contained" onClick={handlePrint} size="large" style={{padding:'10px 40px', backgroundColor:'#052F44', color:'white',}}>Print</Button>
                {
                    app.dqseal_type === SEAL_TYPE.CERTIFY &&
                    status < APP_STATUS.READY_TO_CERTIFY &&
                    <Button variant="contained" onClick={handleGetCertify} size="large" style={{backgroundColor:'#EE6628', padding:'10px 30px', color:'white'}}>Add Impact Tracking</Button>
                }
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </>
    )
}

export default ReportModule;